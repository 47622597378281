<template>
  <a-select
    mode="multiple"
    v-model:value="valueState"
    style="width: 300px"
    placeholder="Pilih Produk"
    allow-clear
    not-found-content="Tidak ditemukan"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    @blur="() => (findText = null)"
    @select="() => (findText = null)"
    v-bind="$attrs"
    :loading="loading"
    :show-arrow="true"
  >
    <a-select-option v-for="d in dataRegional" :key="d.id" :label="d.name">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d.name)"></span>
      <!--eslint-enable-->
    </a-select-option>
  </a-select>
</template>

<script>
import { onMounted, ref, toRefs, reactive, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce } from 'lodash'

export default {
  props: {
    value: {
      default: () => [],
      type: Array,
    },
    brand: {
      default: () => [],
      type: Array,
    },
    is_competitor: {
      default: false,
      type: [Boolean],
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const dataRegional = ref([])
    const findText = ref(null)
    const brand = toRefs(props).brand
    const loading = ref(false)

    const fetchDataRegional = (brand, q = null) => {
      if (brand.length <= 0) brand = null
      loading.value = true
      apiClient
        .get('/api/filter/product?active=1', { params: {
          q,
          brand: brand,
          is_competitor: props.is_competitor,
        } })
        .then(response => {
          const sorted = response.data
          dataRegional.value = sorted.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
          )
        })
        .finally(() => {
          loading.value = false
        })
    }
    onMounted(() => {
      fetchDataRegional(brand.value)
    })
    watch(brand, (after, before) => {
      emit('update:value', [])
      fetchDataRegional(after)
    })

    const onSearch = debounce(value => {
      loading.value = true
      console.log({ value, findText: findText.value })
      findText.value = value
      fetchDataRegional(brand.value, value)
    }, 300)

    const highlight = value => {
      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }
    return {
      dataRegional,
      valueState: useVModel(props, 'value'),
      findText,
      highlight,
      onSearch,
      loading,
    }
  },
}
</script>

<style></style>
